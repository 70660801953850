var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    attrs: {
      "id": "faq-search-filter"
    }
  }, [_c('b-card', {
    staticClass: "faq-search",
    style: {
      backgroundImage: "url(".concat(_vm.bannerImage, ")")
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "text-center"
  }, [_c('h2', {
    staticClass: "text-primary"
  }, [_vm._v(" Let's answer some questions ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" or choose a category to quickly find the help you need ")]), _c('b-form', {
    staticClass: "faq-search-input"
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "searchbar",
      "placeholder": "Search faq..."
    },
    model: {
      value: _vm.faqSearchQuery,
      callback: function callback($$v) {
        _vm.faqSearchQuery = $$v;
      },
      expression: "faqSearchQuery"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('section', {
    attrs: {
      "id": "faq-tabs"
    }
  }, [_c('b-tabs', {
    attrs: {
      "vertical": "",
      "content-class": "col-12 col-md-8 col-lg-9",
      "pills": "",
      "nav-wrapper-class": "faq-navigation col-md-4 col-lg-3 col-12",
      "nav-class": "nav-left"
    },
    scopedSlots: _vm._u([{
      key: "tabs-end",
      fn: function fn() {
        return [_c('b-img', {
          staticClass: "d-none d-md-block mt-auto",
          attrs: {
            "fluid": "",
            "src": require('@/assets/images/illustration/faq-illustrations.svg')
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.faqData, function (categoryObj, categoryName, index) {
    return _c('b-tab', {
      key: categoryName,
      attrs: {
        "active": !index
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('feather-icon', {
            staticClass: "mr-1",
            attrs: {
              "icon": categoryObj.icon,
              "size": "18"
            }
          }), _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(_vm._s(categoryObj.title))])];
        },
        proxy: true
      }], null, true)
    }, [_c('faq-question-answer', {
      attrs: {
        "options": categoryObj
      }
    })], 1);
  }), 1)], 1), _c('section', {
    staticClass: "faq-contact"
  }, [_c('b-row', {
    staticClass: "mt-5 pt-75 text-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', [_vm._v("You still have a question?")]), _c('b-card-text', {
    staticClass: "mb-3"
  }, [_vm._v(" If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly! ")])], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none py-1 faq-contact-card"
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "size": "42",
      "rounded": "",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PhoneCallIcon",
      "size": "18"
    }
  })], 1), _c('h4', [_vm._v("+ (810) 2548 2568")]), _c('span', {
    staticClass: "text-body"
  }, [_vm._v("We are always happy to help!")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none py-1 faq-contact-card"
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "size": "42",
      "rounded": "",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon",
      "size": "18"
    }
  })], 1), _c('h4', [_vm._v("hello@help.com")]), _c('span', {
    staticClass: "text-body"
  }, [_vm._v("Best way to get answer faster!")])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }